export const authentication = {
    state: () => ({
        isLoggedIn: false,
        token: null,
        userData: null,
    }),

    actions: {
        changeStatus ({ commit, state }, token) {
            commit('setToken', token)
            commit('setAuthenticationStatus', token)
        },
        changeUserData({ commit, state }, userData) {
            commit('setUserData', userData)
        },
    },

    mutations: {
        setToken (state, token) {
            state.token = token
        },
        setAuthenticationStatus (state, token) {
            state.isLoggedIn = (token != null)
        },
        setUserData (state, userData) {
            state.userData = userData
        },
    },

    getters: {
        getToken (state) {
            return state.token
        },
        getAuthenticationStatus (state) {
            return state.isLoggedIn
        },
        getUserData (state) {
            return state.userData
        },
    }
}
