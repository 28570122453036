import { createStore } from 'vuex'
import { authentication } from './authentication.js'
import { request } from './request.js'
import { notification } from './notification.js'
import { maintenance } from './maintenance.js'

const store = createStore({
    modules: {
        authentication,
        request,
        notification,
        maintenance
    },
})

export default store
