export const request = {
    state: () => ({
        geoJson: null,
        reason: false,
        requestsAllowed: true,
    }),

    actions: {
        changeGeoJson({ commit, state }, geoJson) {
            commit('setGeoJson', geoJson)
        },
        changeReason({ commit, state }, reason) {
            commit('setReason', reason)
        },
        changeRequestsAllowed({ commit, state }, requestsAllowed) {
            commit('setRequestsAllowed', requestsAllowed)
        }
    },

    mutations: {
        setGeoJson (state, geoJson) {
            state.geoJson = geoJson
        },
        setReason (state, reason) {
            state.reason = reason
        },
        setRequestsAllowed (state, requestsAllowed) {
            state.requestsAllowed = requestsAllowed
        },
    },

    getters: {
        getGeoJson (state) {
            return state.geoJson
        },
        getReason (state) {
            return state.reason
        },
        getRequestsAllowed (state) {
            return state.requestsAllowed
        },
    }
}
