export const maintenance = {
    state: () => ({
        maintenanceLoginHidden: 0,
    }),

    actions: {
        changeMaintenanceLoginHidden({ commit, state }, maintenanceLoginHidden) {
            commit('setMaintenanceLoginHidden', maintenanceLoginHidden)
        }
    },

    mutations: {
        setMaintenanceLoginHidden (state, maintenanceLoginHidden) {
            state.maintenanceLoginHidden = maintenanceLoginHidden;
        },
    },

    getters: {
        getMaintenanceLoginHidden (state) {
            return state.maintenanceLoginHidden;
        },
    }
}
