<template>
  <div class="font-base">
      <router-view/>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
      return {
          timer: null
      }
  },
  created () {
    const auth = this.getCookie('authentication')
    if (auth) {
      let token = auth;

      this.$store.dispatch('changeStatus', token)

      axios.get(process.env.VUE_APP_API_URL + '/api/profile/data',
        {
          headers: {
            'Authorization': `Bearer ${this.$store.getters.getToken}`
          }
        })
        .then(response => {
          this.$store.dispatch('changeUserData', response.data.data);
          
          if (this.$store.getters.getUserData.amountOfInquiries >= this.$store.getters.getUserData.constraints.requests.amount ) {
            this.$store.dispatch('changeRequestsAllowed', false);
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  },
  methods: {
    getCookie: function (cname) {
      const name = cname + '='
      const ca = document.cookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    }
  }
}
</script>
