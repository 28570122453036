import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/store.js'

const routes = [
    /*** Login & Register***/
    {
        path: '/',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: {
            title: 'Login',
            requiresAuth: false
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Register.vue'),
        meta: {
            title: 'Registrierung',
            requiresAuth: false
        }
    },
    {
        path: '/success',
        name: 'Success',
        component: () => import('../views/Success.vue'),
        meta: {
            title: 'Login Versuch',
            requiresAuth: false
        }
    },
    {
        path: '/login/:hash',
        name: 'LoginEmail',
        component: () => import('../views/LoginEmail.vue'),
        props: true,
        meta: {
            title: 'Login Versuch',
            requiresAuth: false
        }
    },
    /*** User ***/
    {
        path: '/user',
        name: 'User',
        component: () => import('../views/User.vue'),
        meta: {
            title: 'Anfragen',
            requiresAuth: true,
        }
    },
    {
        path: '/user/data',
        name: 'UserData',
        component: () => import('../views/UserData.vue'),
        meta: {
            title: 'Benutzer ändern',
            requiresAuth: true,
        }
    },
    /*** Requests ***/
    {
        path: '/anfrage/neu',
        name: 'RequestNew',
        component: () => import('../views/RequestNew.vue'),
        meta: {
            title: 'Neue Anfrage',
            requiresAuth: true
        }
    },
    {
        path: '/anfrage/nicht-gefunden',
        name: 'RequestNotFound',
        component: () => import('../views/RequestNotFound.vue'),
        meta: {
            title: 'Anfrage nicht gefunden',
            requiresAuth: true
        }
    },
    {
        path: '/vorschau/nicht-gefunden',
        name: 'PreviewNotFound',
        component: () => import('../views/PreviewNotFound.vue'),
        meta: {
            title: 'Anfrage Vorschau nicht gefunden',
        }
    },
    {
        path: '/anfrage/neu/daten',
        name: 'RequestData',
        component: () => import('../views/RequestData.vue'),
        meta: {
            title: 'Neue Anfrage',
            requiresAuth: true
        }
    },
    {
        path: '/anfrage/:id',
        name: 'Request',
        component: () => import('../views/Request.vue'),
        props: true,
        meta: {
            title: 'Anfrage',
            requiresAuth: true
        }
    },
    {
        path: '/anfrage/vorschau/:id/token/:token',
        name: 'RequestPreview',
        component: () => import('../views/RequestPreview.vue'),
        props: true,
        meta: {
            title: 'Anfrage Vorschau',
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../views/NotFound.vue'),
        meta: {
            title: 'Seite nicht gefunden'
        }
    },
    /*** Impressum & Datenschutz ***/
    {
        path: '/impressum',
        name: 'Imprint',
        component: () => import('../views/Imprint.vue'),
        meta: {
            title: 'Impressum',
        }
    },
    {
        path: '/datenschutz',
        name: 'Privacy',
        component: () => import('../views/Privacy.vue'),
        meta: {
            title: 'Datenschutz',
        }
    },
    {
        path: '/agb',
        name: 'TermsAndConditions',
        component: () => import('../views/TermsAndConditions.vue'),
        meta: {
            title: 'Allgemeine Geschäftsbedingungen',
        }
    },
    {
        path: '/explanation',
        name: 'Explanation',
        component: () => import('../views/Explanation.vue'),
        meta: {
            title: 'Video-Erklärung',
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const authenticationState = store.getters.getAuthenticationStatus;

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!authenticationState) {
            next({ name: 'Login' })
        }
    } else if (to.matched.some(record => (record.meta.requiresAuth == false))) {
        if (authenticationState) {
            next({ name: 'User' })
        }
    }

    let title = ''
    if (to.meta.title !== '') {
        title += to.meta.title + ' - '
    }
    title += 'einbautenerhebung.at'
    document.title = title
    next()
})

export default router
