export const notification = {
    state: () => ({
        notification: null,
    }),

    actions: {
        changeNotification({ commit, state }, notification) {
            commit('setNotification', notification)
        }
    },

    mutations: {
        setNotification (state, notification) {
            state.notification = notification
        },
    },

    getters: {
        getNotification (state) {
            return state.notification
        },
    }
}
